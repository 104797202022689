const ImageMessage = (message, currentUser) => {
  const url = message.message;
  return (
    <div
      className={`${
        currentUser ? "sent-msg" : "receive-msg"
      } more-content img-receive`}
    >
      <img src={url} alt="" />

      
    </div>
  );
};
export default ImageMessage;
