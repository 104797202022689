import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  setStorageJson,
  setStorage,
  getStorage,
  getStorageJson,
  removeStorage,
} from "../utils/localStorage";
const initialState = {
  user: {},
  totalUsers: 0,
  userToApprove: [],
  userManagement: [],
  userManagementTotalUser: 0,
  updateUser: {},
  token: "",
  isLoggedIn: false,
  lastLogin: {},
  UsersContentSharedwith: [],
  walletWithDrawal: [],
  walletWithDrawalExport: [],
  walletWithDrawalDetail: [],
  walletWithDrawalBankDetail: [],
  walletWithDrawalAddressDetail: [],
  total_walletWithDrawal: 0,
  paymentHistory: [],
  total_paymentHistory: 0,
  subscriptions: [],
  total_subscriptions: 0,
  total_reports: 0,
  reports: [],
  subscribers: [],
  total_subscribers: 0,
  all_subscribers: [],
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    set: (state, action) => {
      let token = getStorage("token");
      let user = getStorageJson("user");

      if (token && token.length > 0) {
        state.isLoggedIn = true;
        if (user) {
          state.user = user;
          state.token = token;
        }
      }
    },
    resetContentSharedWith: (state, action) => {
      state.UsersContentSharedwith = [];
    },
    setUserData: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.user = action.user;
    },
    removeUserData: (state) => {
      state.user = {};
    },

    setUserLogin: (state, action) => {
      state.islogin = action.islogin;
    },
    updateUserData: (state, action) => {
      state.updateUser = { ...state.updateUser, ...action.payload };
    },

    // Use the PayloadAction type to declare the contents of `action.payload`
    // incrementByAmount: (state, action) => {
    //   state.value += action.payload;
    // },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: {
    LoginUser: (state, action) => {
      if (action.payload) {
        let payload = action.payload;
        if (payload.authToken) {
          state.isLoggedIn = true;
          state.user = payload.user;
          setStorageJson("user", payload.user);
          setStorage("token", payload.authToken);
        }
      }
    },
    SignUp: (state, action) => {
      if (action.payload) {
        let payload = action.payload;
        if (payload.authToken) {
          state.isLoggedIn = true;
          state.user = payload.user;
          setStorageJson("user", payload.user);
          setStorage("token", payload.authToken);
        }
      }
    },
    LoadUserToApprove: (state, action) => {
      if (action.payload && action.payload.users) {
        state.userToApprove = action.payload.users;
        state.totalUsers = action.payload.total
      }
    },
    LoadUserForManagement: (state, action) => {
      if (action.payload && action.payload.users) {
        state.userManagement = action.payload.users;
        state.userManagementTotalUser = action.payload.total
      }
    },
    ListUsersContentSharedwith: (state, action) => {
      if (action.payload) {
        state.UsersContentSharedwith = action.payload;
      }
    },
    ApproveUserUpdate: (state, action) => {
      const user = action.payload;
      let userToApprove = Object.assign([], state.userToApprove);
      if (userToApprove.length > 0) {
        let index = -1;
        for (let [i, iterator] of userToApprove.entries()) {
          if (iterator.id === user.id) {
            index = i;
          }
        }
        if (index !== -1) {
          userToApprove.splice(index, 1);
        }
      }
      state.userToApprove = userToApprove;
    },
    LoadLastLogin: (state, action) => {
      if (action.payload) {
        state.lastLogin = action.payload;
      }
    },
    LoadReports: (state, action) => {
      if (action.payload && action.payload.data) {
        state.reports = action.payload.data;
        state.total_reports = action.payload.total
      }
    },
    LoadWithDrawalRequest: (state, action) => {
      if (action.payload && action.payload.data) {
        state.walletWithDrawal = action.payload.data;
        state.total_walletWithDrawal = action.payload.total;
      }
    },

    LoadWithDrawalExport: (state, action) => {
      if (action.payload && action.payload.data) {
        state.walletWithDrawalExport = action.payload.data;
      }
    },

    LoadWithDrawalRequestById: (state, action) => {
      if (action.payload && action.payload.data) {
        state.walletWithDrawalDetail = action.payload.data;
        state.walletWithDrawalBankDetail = action.payload.bank;
        state.walletWithDrawalAddressDetail = action.payload.creatorAddress;
      }
    },
    ApproveWithDrawalRequest: (state, action) => {
      if (action.payload) {
        let walletWithDrawal = state.walletWithDrawal;
        let index = -1;
        for (let [i, iterator] of walletWithDrawal.entries()) {
          if (iterator.id === action.payload.id) {
            index = i;
          }
        }
        if (index !== -1) {
          walletWithDrawal.splice(index, 1);
        }
        state.walletWithDrawal = walletWithDrawal;
        state.total_walletWithDrawal = (state.total_walletWithDrawal-1)
      }
    },
    LoadWithPaymentHistory: (state, action) => {
      if (action.payload && action.payload.data) {
        state.paymentHistory = action.payload.data;
        state.total_paymentHistory = action.payload.total
      }
    },
    LoadWithSubscriptions: (state, action) => {
      if (action.payload && action.payload.data) {
        state.subscriptions = action.payload.data;
        state.total_subscriptions = action.payload.total
      }
    },

    LoadSubscriberForManagement: (state, action) => {
      if (action.payload && action.payload.data) {
        state.subscribers = action.payload.data;
        state.total_subscribers = action.payload.total;
        state.all_subscribers = action.payload.allSubscribers
      }
    },

    ResolveReports: (state, action) => {
      if (action.payload) {
        const deletedReports = action.payload;
        let reports = Object.assign([], state.reports);
        if (reports.length > 0) {
          let index = -1;
          for (let [i, iterator] of reports.entries()) {
            if (iterator.id === deletedReports.id) {
              index = i;
            }
          }
          if (index !== -1) {
            reports.splice(index, 1);
          }
        }
        state.reports = reports;
      }
    },
  },
});

export const {
  setUserData,
  removeUserData,
  setUserLogin,
  updateUserData,
  set,
  LoadReports,
  resetContentSharedWith
} = userSlice.actions;
export const userData = (state) => state.user;
export default userSlice.reducer;
