import React, { useEffect, useState, useCallback } from "react";
import update from 'immutability-helper';
import Header from "./Header";
import Footer from "./Footer";
import SideBard from "./SideBard";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Card } from "../../components/DragCard";
import { useDispatch } from "react-redux";
import { saveData, fetchData } from "../../utils/api/apiHelper";
import {
  LoadSortCreator,
  SortCreator
} from "../../utils/api/actionConstants";
import { toast } from "react-toastify";

const SortCreators = () => {
  const [cards, setCards] = useState([]);
  const dispatch = useDispatch();

  const onSave = () => {
    dispatch(
      saveData({
        action: SortCreator,
        data: cards,
      })
    ).then(resp => {
      if(resp){
        toast("Order updated successfully");
      }
    })
  }

  useEffect(() => {
    dispatch(
      fetchData({
        action: LoadSortCreator,
        data: {},
        sendAll: true
      })
    ).then(resp => {
      if (!resp || !resp.length || resp.length < 1 || resp === "with error") return;
      setCards(resp);
    })
  }, [dispatch]);

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setCards((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      }),
    )
  }, [])

  const renderCard = useCallback((card, index) => {
    let text = '<div class="list-order"><figure><img src="'+card.profilePic+'"></figure><p>'+card.name+' ('+card.age+')</p></div>';
    return (
      <Card
        key={card.id}
        index={index}
        id={card.id}
        text={text}
        moveCard={moveCard}
      />
    );
  }, []);

  return (
    <>
      <Header />
      <main>
        <SideBard />
        <div className="main review">
          <div className="container">
            <div className="row">
              <div className="content-wrapper w-100 mb-5">
                <div className="creator-order-wrap">
                  <h1 className="pageHeading my-4">Sort Creators</h1>
                    <div className="button-save-wrap">
                      <button className="btn btn-green order-save" onClick={() => onSave()}>Save</button>
                    </div>
                </div>
                <div className="content">
                  <DndProvider backend={HTML5Backend}>
                    <div className="creator-order-list-wrap">
                      {cards && cards.length > 0 && cards.map((card, i) => renderCard(card, i))}
                    </div>
                  </DndProvider>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default SortCreators;
