import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import SideBard from "./SideBard";
import { useDispatch, useSelector } from "react-redux";
import { fetchReportData } from "../../utils/api/apiHelper";
import useCustomNavigate from "../../utils/hooks/useCustomNavigate";
import { LoadChats,LoadChatMessage } from "../../utils/api/actionConstants";
import { Images } from "../../themes";
import { getHourMinute, getAmPm, getDate,getDateFull } from "../../utils/Common";
import { useChatList, useChatMessages, useHasMore,useUser } from "../../hooks/storeHooks";
import {useParams, useLocation} from 'react-router-dom';
import InfiniteScroll from "react-infinite-scroll-component";
import TextMessage from "../../components/chat/TextMessage";
import ImageMessage from "../../components/chat/ImageMessage";
import PriceMessage from "../../components/chat/PriceMessage";
import VideoMessage from "../../components/chat/VideoMessage";
import { ClearChatMessage } from "../../redux/chatReducer";
import userIcon from '../../assets/images/useIcon.png';
const Chat = () => {
  const [NavigateTo] = useCustomNavigate();
  const dispatch = useDispatch();
  const {id} = useParams();
  const location = useLocation();
  const list = useChatList();
  const [chat, setChat] = useState({});
  const [loading, setLoading] = useState(false);
  const chatMessages = useChatMessages();
  const hasMore = useHasMore();
  const  user = location.state.user;
  useEffect(() => {
    dispatch(fetchReportData({ action: LoadChats,data:{ id :id} }));
  }, []);
  const getMessageUser = (v) => {
    if (v.firstUser && v.firstUser.id === id) {
      return v.secondUser;
    }
    if (v.secondUser && v.secondUser.id === id) {
      return v.firstUser;
    }
  };
  function isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  function isImage(url) {
    return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
  }
  function isVideo(url) {
    return /\.(mp4|mpeg4|mov|avi|flv|mkv)$/.test(url);
  }

  const showLastMessage = (message) => {
    if (isJson(message)) {
      const data = JSON.parse(message);
      return "" + data.currency + data.amount;
    }

    if (isImage(message)) {
      return "MD_IMAGE_VIDEO";
    }

    if (isVideo(message)) {
      return "MD_IMAGE_VIDEO";
    }

    return message;
  };
  const getChatMessage= (v) => {
    //setLoading(true);
    dispatch(ClearChatMessage())
    setChat(v)
    dispatch(fetchReportData({ action: LoadChatMessage, data: { id: v.id } }));
  };
  const fetchOlderChat = () => {
    if (chatMessages.length > 0) {
      console.log(chatMessages[0]);
      dispatch(
        fetchReportData({
          action: LoadChatMessage,
          data: { id: chat.id, lastMessageAt: chatMessages[0].createdAt },
        })
      );
    }
  };
  useEffect(() => () => { dispatch(ClearChatMessage()); setChat({})}, []);
  const ChatRenderer = () => {
    let msg = [...chatMessages];
    
    if (msg.length === 0) {
      return;
    }
    let currentDate = new Date(getDate(msg[msg.length - 1].createdAt));
    let showDate = msg[msg.length - 1].createdAt;
    return msg.reverse().map((row, k) => {
      if (currentDate > new Date(getDate(row.createdAt))) {
        let ss = showDate;
        currentDate = new Date(getDate(row.createdAt));
        showDate = row.createdAt;
        return (
          <>
            <div className="chat-date">{getDateFull(ss)}</div>
            <div key={k}>{SelectMessageTemplate(row)}</div>
          </>
        );
      }

      return <div key={k}>{SelectMessageTemplate(row)}</div>;
    });
  };

  const SelectMessageTemplate = (row) => {
    switch (row.chatType.name) {
      case "Message":
        return TextMessage(row, row.userId === id);
        case "Image":
          return ImageMessage(
            row,
            row.userId === id
          );
        case "Video":
          return VideoMessage(
            row,
            row.userId === id
          );
        case "Payment Request":
          return (
            <div>
              
              {PriceMessage(row, row.userId === id, {
                type: "Payment Request",
              })}
            </div>
          );
  
        case "Processing Payment":
          return PriceMessage(row, row.userId === id, {
            type: "Processing Payment",
          });
      default:
        return TextMessage(row, row.userId === id);
    }
  };
  
  return (
    <>
      <Header />
      <main>
        <SideBard />
        <div className="main review chat">
          <div className="container">
          <div className="content">
          <div className="row">
            <div className="col-12">
              <div className="card usercard mb-4 mt-4">
                <div className="card-body">
                  <div className="d-flex userDetails">
                    <div className="icon icon-circle icon-lg">
                      <div className="icon-user">
                        <img src={user.profilePic || userIcon} alt="Profile Image" />
                      </div>
                    </div>
                    <div className="userinfo">
                      <div className="mb-3">
                        <span className="text-bold lbl">Name <strong>:</strong></span> 
                        <span className="username val">
                          {user?.name}
                        </span>
                      </div>
                      <div className="mb-3">
                        <span className="text-bold lbl">Email <strong>:</strong></span>
                        <span className="username val">
                          <span className="mlink">
                            {user?.email}
                          </span>
                        </span>
                      </div>
                      <div className="mb-3">
                        <span className="text-bold lbl">
                          Phone No. <strong>:</strong>
                        </span>
                      
                        <span className="username val">
                          {user?.mobileNumber}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="content-wrapper w-100 mb-5">
                <div className="content">
                  <div className="row">
                  {list.length > 0?( 
                    <>
                  <div className="col-4">
                    <div className="card">
                      <div className="card-body">
                        <div className="chat-items">
                          {list && list.length > 0 && (
                            list.map((v, k) => {
                              let chatUser = getMessageUser(v);
                              return (
                                <div
                                  className={`chat-list ${
                                    v.id === chat.id ? "active-chat" : ""
                                  } ${
                                    v.sender && v.sender !== id && !v.isRead
                                      ? "unreadMessage"
                                      : ""
                                  }`}
                                  
                                  key={k}
                                  onClick={(e) => getChatMessage(v)}
                                >
                                  <div className="chat-img">
                                    <figure>
                                      <img
                                        src={
                                          chatUser && chatUser?.profilePic !== ""
                                            ? chatUser?.profilePic
                                            : Images.useIcon
                                        }
                                      />
                                    </figure>
                                  </div>
                                  <div className="chat-detail chat-middle">
                                    <h3>{chatUser && chatUser.name}</h3>
                                    <p>
                                      {showLastMessage(v.lastMessage) === "MD_IMAGE_VIDEO" ? (
                                        <img src={Images.mediaIcon} alt="" />
                                      ) : (
                                        showLastMessage(v.lastMessage)
                                      )}
                                    </p>
                                  </div>
                                  <div className="chat-detail chat-time">
                                    <p>{getDate(v.lastMessageAt)}</p>
                                    <p>
                                      {getHourMinute(v.lastMessageAt)}
                                      {getAmPm(v.lastMessageAt)}
                                    </p>
                                  </div>
                                </div>
                              );
                            })
                          ) }
                        </div>
                      </div>
                    </div>
                  </div>
                      <div className="col-8">
                        <div class="card">
                          <div class="card-body">
                            {chatMessages && chatMessages?.length >0 && (
                            <div
                              className="chat-box-inner"
                              id="scrollableDiv"
                              style={{
                                overflow: "auto",
                                display: "flex",
                                flexDirection: "column-reverse",
                              }}
                            >
                              <InfiniteScroll
                                dataLength={chatMessages?.length} //This is important field to render the next data
                                next={() => fetchOlderChat()}
                                hasMore={hasMore}
                                style={{ display: "flex", flexDirection: "column-reverse" }} //To put endMessage and loader to the top.
                                inverse={true} //
                                loader={<h4 className="chat-loading">Loading...</h4>}
                                scrollableTarget="scrollableDiv"
                                // endMessage={
                                //   <p style={{ textAlign: "center" }}>
                                //     <b>Yay! You have seen it all</b>
                                //   </p>
                                // }
                              >
                                {ChatRenderer()}
                              </InfiniteScroll>
                            </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </> ):(
                          <div className="card">
                              <div className="card-body">
                                <p className="no-chat-message">No chats found</p>
                              </div>
                            </div>
                          )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default Chat;
