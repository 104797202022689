import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  setStorageJson,
  setStorage,
} from "../utils/localStorage";
const initialState = {
  creator: {},
  content: [],
  setFreeProfile: false,
  totalContent: 0,
  moneyEarned: 0,
  moneySpent: 0,
  contentR: [],
  contentX: [],
  private: [],
  contentRHasMore: false,
  contentXHasMore: false,
  profilePics: [],
};

export const folderSlice = createSlice({
  name: "creatorContent",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    CleanupViewFiles: (state, action) => {
      state.creator = {};
      state.setFreeProfile = false;
      state.content = [];
      state.totalContent = 0;
      state.contentR = [];
      state.contentX = [];
      state.profilePics = [];
    },
    CleanupContentFiles: (state, action) => {
      state.content = [];
      state.totalContent = 0;
      state.contentR = [];
      state.contentX = [];
      state.profilePics = [];
    },
  },
  extraReducers: {
    SetCurrentCreator: (state, action) => {
      state.creator = action.payload;
    },
    SetUserAsFree: (state, action) => {
      if (action.payload && action.payload.id) {
        state.setFreeProfile = true;
      } else {
        state.setFreeProfile = false;
      }
    },
    LoadSetFreeProfile: (state, action) => {
      if (action.payload && action.payload.id) {
        state.setFreeProfile = true;
      } else {
        state.setFreeProfile = false;
      }
    },
    UserApproveApi: (state, action) => {
      if (action.payload) {
        let creator = Object.assign({}, state.creator);
        creator.isApproved = action.payload.status;
        state.creator = creator;
      }
    },
    UserScoreApi: (state, action) => {
      if (action.payload) {
        let creator = Object.assign({}, state.creator);
        creator.profileScore = action.payload.profileScore;
        state.creator = creator;
      }
    },
    LoadFolder: (state, action) => {
      state.folderR = action.payload.R;
      state.folderX = action.payload.X;
    },
    ListCreatorPrivatePics: (state, action) => {
      if (action.payload && action.payload.data) {
        state.private = action.payload.data;
      }
    },
    LoadlCreatorContent: (state, action) => {
      if (action.payload && action.payload.data) {
        state.content = action.payload.data;
        state.totalContent = action.payload.total
      }
    },
    ListContentSharedwith: (state, action) => {
      if (action.payload && action.payload.content) {
        state.content = action.payload.content;
        state.totalContent = action.payload.total
      }
    },
    LoadCreatorEarnings: (state, action) => {
      if (action.payload && action.payload.length > 0) {
        state.moneyEarned = action.payload[0].total;
      }
    },
    LoadConsumerExpense: (state, action) => {
      if (action.payload && action.payload.length > 0) {
        state.moneySpent = action.payload[0].total;
      }
    },
    ListCreatorContentX: (state, action) => {
      if (action.payload) {
        let { data, hasMore } = action.payload;
        if (state.contentX.length > 0) {
          if (data.length > 0) {
            let last = state.contentX[(state.contentX.length - 1)];
            let newLast = data[(data.length - 1)];
            if (newLast && newLast.id != last.id) {
              let content = state.contentX;
              content = [...content, ...data];
              state.contentX = content;
              state.contentXHasMore = hasMore;
            }
          }
        } else {
          state.contentX = data;
          state.contentXHasMore = hasMore;
        }
      }
    },
    ListCreatorContentR: (state, action) => {
      if (action.payload) {
        let { data, hasMore } = action.payload;
        if (state.contentR.length > 0) {
          if (data.length > 0) {
            let last = state.contentR[(state.contentR.length - 1)];
            let newLast = data[(data.length - 1)];
            if (newLast && newLast.id != last.id) {
              let content = state.contentR;
              content = [...content, ...data];
              state.contentR = content;
              state.contentRHasMore = hasMore;
            }
          }
        } else {
          state.contentR = data;
          state.contentRHasMore = hasMore;
        }
      }
    },
    ListCreatorProfilePics: (state, action) => {
      if (action.payload) {
        if (state.profilePics.length > 0) {
          let last = state.profilePics[(state.profilePics.length - 1)];
          let newLast = action.payload[(action.payload.length - 1)];
          if (newLast && newLast.id != last.id) {
            let content = state.profilePics;
            content = [...content, action.payload];
            state.profilePics = content;
          }
        } else {
          state.profilePics = action.payload;
        }
      }
    },
  },
});

export const { LoadFolder, fetchFolderFiles, CleanupViewFiles, CleanupContentFiles } = folderSlice.actions;
export const myCreatorContent = (state) => state.creatorContent;
export default folderSlice.reducer;
