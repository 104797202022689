import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import SideBard from "./SideBard";
import { LoadWithDrawalRequestById } from "../../utils/api/actionConstants";
import { CreatorWalletWithDrawalDetail } from "../../hooks/storeHooks";
import { useDispatch } from "react-redux";
import { fetchData } from "../../utils/api/apiHelper";
import { useParams } from "react-router-dom";
import { getAmPm, getHourMinute, viewDateDDMMYYY } from "../../utils/Common";

const WalletWithdrawalView = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const walletWithDrawalDetail = CreatorWalletWithDrawalDetail();
    useEffect(() => {
        dispatch(
          fetchData({
            action: LoadWithDrawalRequestById,
            data: {
              id
            }
          })
        );
      }, [id]);

    return (
        <>
         <Header />
         <main>
            <SideBard />
            <div className="main">
                <div className="container">
                    <div className="row">
                        <div className="content-wrapper w-100 mb-5">
                            <div className="d-flex aic jsb flw">
                                <h1 className="pageHeading my-4">
                                    Wallet Withdrawal View
                                </h1>
                            </div>
                        </div>    
                    </div>
                    <div className="content">
                        <div className="row">
                            <div className="col-6">
                                <div className="card mb-4">
                                    <div className="card-body">
                                    <div className="d-flex userDetails">
                                        <div className="icon icon-circle icon-lg">
                                        <div className="icon-user">
                                            <img src={walletWithDrawalDetail.walletWithDrawalDetail?.user?.profilePic} alt="" />
                                        </div>
                                        </div>
                                        <div className="userinfo">
                                        <div className="mb-3">
                                            <span className="text-bold lbl">Name <strong>:</strong></span> 
                                            <span className="username val">
                                            {walletWithDrawalDetail.walletWithDrawalDetail?.user?.name}
                                            </span>
                                        </div>
                                        <div className="mb-3">
                                            <span className="text-bold lbl">Req. Date <strong>:</strong></span>
                                            <span className="username val">
                                                {viewDateDDMMYYY(walletWithDrawalDetail.walletWithDrawalDetail?.createdAt)}
                                            </span>
                                           
                                        </div>
                                        <div className="mb-3">
                                            <span className="text-bold lbl">Req. Time <strong>:</strong></span>
                                            <span className="username val">
                                                {getHourMinute(walletWithDrawalDetail.walletWithDrawalDetail?.createdAt)} {getAmPm(walletWithDrawalDetail.walletWithDrawalDetail?.createdAt)}
                                            </span>
                                        </div>
                                        <div className="mb-3">
                                            <span className="text-bold lbl">Request Mode <strong>:</strong></span>
                                            <span className="username val">
                                                {walletWithDrawalDetail.walletWithDrawalDetail?.sourceType}
                                            </span>
                                        </div>
                                        <div className="mb-3">
                                            <span className="text-bold lbl">Amount <strong>:</strong></span>
                                            <span className="username val">
                                            ${ walletWithDrawalDetail.walletWithDrawalDetail?.amount}
                                            </span>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {walletWithDrawalDetail.walletWithDrawalAddressDetail ? (
                                <div className="card mb-4">
                                    <div className="card-body">
                                        <div className="d-flex userDetails">
                                        <div className="userinfo">
                                            <div className="mb-3">
                                                <span className="text-bold lbl">Address Detail <strong>:</strong></span>
                                                <span className="username val">
                                                    {walletWithDrawalDetail.walletWithDrawalAddressDetail?.lane1} &nbsp;
                                                    {walletWithDrawalDetail.walletWithDrawalAddressDetail?.lane2} &nbsp;
                                                    {walletWithDrawalDetail.walletWithDrawalAddressDetail?.city} &nbsp;
                                                    {walletWithDrawalDetail.walletWithDrawalAddressDetail?.state} &nbsp;
                                                    {walletWithDrawalDetail.walletWithDrawalAddressDetail?.country} &nbsp;
                                                    {walletWithDrawalDetail.walletWithDrawalAddressDetail?.zipCode} &nbsp;
                                                </span>
                                            </div>
                                        </div>
                                        </div>
                                   </div>
                                </div>   
                                ) : (
                                    null
                                )}
                                {walletWithDrawalDetail.walletWithDrawalBankDetail ? (
                                <div className="card mb-4">
                                    <div className="card-body">
                                        <div className="d-flex userDetails">
                                        <div className="userinfo">
                                            <div className="mb-3">
                                                <span className="text-bold lbl">Bank Detail</span>
                                            </div>
                                            <div className="mb-3">
                                                <span className="text-bold lbl">Name <strong>:</strong></span>
                                                <span className="username val">
                                                    {walletWithDrawalDetail.walletWithDrawalBankDetail?.holderName}
                                                </span>
                                            </div>
                                            <div className="mb-3">
                                                <span className="text-bold lbl">Bank Name <strong>:</strong></span>
                                                <span className="username val">
                                                    {walletWithDrawalDetail.walletWithDrawalBankDetail?.bankName}
                                                </span>
                                            </div>
                                            <div className="mb-3">
                                                <span className="text-bold lbl">Acc. Number <strong>:</strong></span>
                                                <span className="username val">
                                                    {walletWithDrawalDetail.walletWithDrawalBankDetail?.acountNumber}
                                                </span>
                                            </div>
                                            <div className="mb-3">
                                                <span className="text-bold lbl">Routing <strong>:</strong></span>
                                                <span className="username val">
                                                    {walletWithDrawalDetail.walletWithDrawalBankDetail?.bankIFSC}
                                                </span>
                                            </div>
                                            <div className="mb-3">
                                                <span className="text-bold lbl">Holder Add. <strong>:</strong></span>
                                                <span className="username val">
                                                    {walletWithDrawalDetail.walletWithDrawalBankDetail?.holderAddress}
                                                </span>
                                            </div>
                                            <div className="mb-3">
                                                <span className="text-bold lbl">Bank Add. <strong>:</strong></span>
                                                <span className="username val">
                                                    {walletWithDrawalDetail.walletWithDrawalBankDetail?.bankAddress}
                                                </span>
                                            </div>
                                            <div className="mb-3">
                                                <span className="text-bold lbl">Bank State <strong>:</strong></span>
                                                <span className="username val">
                                                    {walletWithDrawalDetail.walletWithDrawalBankDetail?.bankState}
                                                </span>
                                            </div>
                                            <div className="mb-3">
                                                <span className="text-bold lbl">Bank City <strong>:</strong></span>
                                                <span className="username val">
                                                    {walletWithDrawalDetail.walletWithDrawalBankDetail?.bankCity}
                                                </span>
                                            </div>
                                        </div>
                                        </div>
                                   </div>
                                </div>   
                                 ) : (
                                    null
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
        </main>
        <Footer />
        </>
    )
}

export default WalletWithdrawalView;