import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import Header from "./Header";
import Footer from "./Footer";
import SideBard from "./SideBard";
import { useCreator } from "../../hooks/storeHooks";
import useCustomNavigate from "../../utils/hooks/useCustomNavigate";
import { fetchData } from "../../utils/api/apiHelper";
import { ListCreatorProfilePics, ListCreatorPrivatePics } from "../../utils/api/actionConstants";
import { CleanupContentFiles } from "../../redux/creatorContent";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { renderDate } from "../../utils/helper";
import 'react-tabs/style/react-tabs.css';
import userIcon from '../../assets/images/useIcon.png';
import idIcon from '../../assets/images/id_default.jpg';
import idProfileIcon from '../../assets/images/default-profile.jpeg';

const ViewCreatorContent = (props) => {
  const [NavigateTo] = useCustomNavigate();
  const localtion = useLocation();
  const dispatch = useDispatch();
  const Creator = useCreator();

  useEffect(() => {
    if (
      !localtion.state ||
      !localtion.state.creator ||
      !localtion.state.creator.id
    ) {
      NavigateTo("/");
    }
  }, []);

  const creator = localtion.state.creator;

  useEffect(() => {
    dispatch(
      fetchData({
        action: ListCreatorProfilePics,
        data: {
          id: creator.id,
        },
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      fetchData({
        action: ListCreatorPrivatePics,
        data: {
          id: creator.id,
        },
      })
    );
  }, [dispatch]);

  /**
   * this hook is for cleaning up the redux store when we move out.
   */
  useEffect(() => () => dispatch(CleanupContentFiles()), []);

  return (
    <>
      <Header />
      <main>
        <SideBard />
        <div className="main">
          <div className="container">
            <div className="row">
              <div className="content-wrapper w-100 mb-5 creator-content-top">
                <div className="d-flex aic jsb flw">
                  <h1 className="pageHeading my-4">Content Library For <span>: {creator?.name}</span></h1>
                </div>
                <div className="content creator-content-tab">
                  <div className="row">
               
                    <Tabs>
                    <TabList>
                      <Tab>Age Verification Images</Tab>
                      <Tab>Profile Information</Tab>
                      <Tab>Free Profile Images</Tab>
                      {/* <Tab>Private Introductory Images</Tab> */}
                    </TabList>

                    <TabPanel>
                      <div className="row age-vrf-row">
                      <div className="col-6">
                        <div className="d-flex aic jsb flw ">
                          <h4 className="pageTitle-2"> Age Verification Image</h4>
                        </div>
                        <div className="col-12">
                          <div className="card-body lib-img age-vrf-imgbox">
                              {" "}
                              <img src={creator?.uploadProfilePicture || idProfileIcon} alt="Id Profile Image" />{" "}
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="d-flex aic jsb flw ">
                          <h4 className="pageTitle-2"> ID Image</h4>
                        </div>
                        <div className="col-12">
                          <div className="card-body lib-img age-vrf-imgbox">
                              {" "}
                              <img src={creator?.photoIdentity || idIcon} alt="Id Card Image" />{" "}
                          </div>
                        </div>
                      </div>
                      </div>
                    </TabPanel>

                    <TabPanel>
                      
                  <div className="content">
                  <div className="row">
                    <div className="col-6">
                      <div className="card mb-4">
                        <div className="card-body">
                          <div className="d-flex userDetails">
                            <div className="icon icon-circle icon-lg">
                              <div className="icon-user">
                                <img src={Creator.creator.profilePic || userIcon} alt="Profile Image" />
                              </div>
                            </div>
                            <div className="userinfo">
                              <div className="mb-3">
                                <span className="text-bold lbl">Name <strong>:</strong></span> 
                                <span className="username val">
                                  {Creator.creator?.name}
                                </span>
                              </div>
                              <div className="mb-3">
                                <span className="text-bold lbl">Email <strong>:</strong></span>
                                <span className="username val">
                                  <span className="mlink">
                                    {Creator.creator?.email}
                                  </span>
                                </span>
                              </div>
                              <div className="mb-3">
                                <span className="text-bold lbl">
                                  Phone No. <strong>:</strong>
                                </span>
                              
                                <span className="username val">
                                  {Creator.creator?.mobileNumber}
                                </span>
                              </div>
                              <div className="mb-3">
                                <span className="text-bold lbl">Title <strong>:</strong></span> 
                                <span className="username val">
                                  {Creator.creator?.heading}
                                </span>
                              </div>

                              <div className="mb-3">
                                <span className="text-bold lbl">About <strong>:</strong></span> 
                                <span className="username val">
                                  {Creator.creator?.aboutMe}
                                </span>
                              </div>
                              <div className="mb-3">
                                <span className="text-bold lbl">Body Type <strong>:</strong></span>
                            
                                <span className="username val">
                                  {Creator.creator?.bodyType}
                                </span>
                              </div>
                              <div className="mb-3">
                                <span className="text-bold lbl">Age <strong>:</strong></span>
                                <span className="username val">
                                  {Creator.creator?.age}
                                </span>
                              </div>
                              <div className="mb-3">
                                <span className="text-bold lbl">Role <strong>:</strong></span>
                                <span className="username val">
                                  {Creator.creator?.role?.name}
                                </span>
                              </div>
                              <div className="mb-3">
                                <span className="text-bold lbl">
                                  Sign Up Date <strong>:</strong>
                                </span>
                              
                                <span className="username val">
                                  {renderDate(Creator.creator?.createdAt)}
                                </span>
                              </div>
                              <div className="mb-3">
                                <span className="text-bold lbl">Ethnicity <strong>:</strong></span>
                                
                                <span className="username val">
                                  {Creator.creator?.ethnicity}
                                </span>
                              </div>

                              <div className="mb-3">
                                <span className="text-bold lbl">Zipcode <strong>:</strong></span> 
                                <span className="username val">
                                  {Creator.creator?.zipcode}
                                </span>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
                    </div>
                
                    </TabPanel>

                    <TabPanel>
                    <div className="col-12">
                    <div className="d-flex aic jsb flw ">
                      <h4 className="pageTitle-2"> Free Profile Images</h4>
                    </div>
                    <div className="row free-prof-row">
                    {Creator.profilePics &&
                      Creator.profilePics.length > 0 &&
                      Creator.profilePics.map((v, k) => {
                        return (
                          <div className="col-3" key={k}>
                            <div className="card tac">
                              <div className="card-body lib-img age-vrf-imgbox">
                                {" "}
                                <img src={v.source} alt="" />{" "}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      </div>
                      </div>
                    </TabPanel>

                    <TabPanel>
                    <div className="d-flex aic jsb flw ">
                      <h4 className="pageTitle-2">Private Images : <span>${Creator?.creator?.contentAmount}</span></h4>
                    </div>
                    <div className="row free-prof-row">
                    {Creator.private &&
                      Creator.private.length > 0 &&
                      Creator.private.map((v, k) => {
                        return (
                          <div className="col-3" key={k}>
                            <div className="card tac">
                              <div className="card-body lib-img age-vrf-imgbox">
                                {" "}
                                <img src={v.source} alt="" />{" "}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      </div>
                    </TabPanel>
                  </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
export default ViewCreatorContent;
