import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
  chatMessages: [],
  hasMore: false,
};

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    ClearChatMessage: (state, action) => {
      state.chatMessages = [];
      state.currentChat = {};      
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: {
    LoadChats: (state, action) => {
      if (action.payload) {
        state.list = action.payload.data;
      }
    },
    LoadChatMessage: (state, action) => {
     
      if (action.payload.data) {
        if (action.payload.data.length === 20) {
          state.hasMore = true;
        } else {
          state.hasMore = false;
        }
        if(state.chatMessages){
          state.chatMessages = [...action.payload.data, ...state.chatMessages];
        }else{
          state.chatMessages = action.payload.data;
        }
        
      }
    },
  },
});

export const { ClearChatMessage } = chatSlice.actions;
export const Chats = (state) => state.chat;
export default chatSlice.reducer;
