import { parse } from "../../utils/Common";

const PriceMessage = (message, currentUser, actions) => {
  let body = parse(message.message);

  return (
    <div className={`${currentUser ? "sent-msg" : "receive-msg"} more-content`}>
      <h3 className="price">
        {body.currency}
        {body.amount}
      </h3>
      {actions.type === "Processing Payment" && "Payment Sent"}
      {currentUser &&
        actions.type === "Payment Request" &&
        "Payment Request Sent"}
      {!currentUser && actions.type === "Payment Request" && !body.isPaid && (
        <div className="chat-btn-block">
          <span
            className="Pay-btn"
           
          >
            Waiting for payment
          </span>
          
        </div>
      )}
      {!currentUser && actions.type === "Payment Request" && body.isPaid && (
        <>Paid</>
      )}
    </div>
  );
};

export default PriceMessage;
