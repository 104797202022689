import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import SideBard from "./SideBard";
import Pagination from "../../components/Pagination";
import { useDispatch } from "react-redux";
import { fetchData, saveData } from "../../utils/api/apiHelper";
import { LoadWithDrawalRequest, ApproveWithDrawalRequest, LoadWithDrawalExport } from "../../utils/api/actionConstants";
import { CreatorWalletWithDrawal, CreatorWalletWithDrawalExport } from "../../hooks/storeHooks";
import { generateRandomString, getAmPm, getHourMinute, viewDateDDMMYYY } from "../../utils/Common";
import useCustomNavigate from "../../utils/hooks/useCustomNavigate";
import Swal from "sweetalert2";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const WalletWithdrawal = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const walletWithDrawal = CreatorWalletWithDrawal();
  const walletWithDrawalExport = CreatorWalletWithDrawalExport();
  console.log(walletWithDrawalExport.walletWithDrawalExport);
  const dispatch = useDispatch();
  const [NavigateTo] = useCustomNavigate();
  let PageSize = 10;

  useEffect(() => {
    dispatch(
      fetchData({
        action: LoadWithDrawalRequest,
        data: {
          limit: 10,
          offset: (currentPage - 1) * PageSize, 
        }
      })
    );
  }, [dispatch, currentPage]);

  useEffect(() => {
    dispatch(
      fetchData({
        action: LoadWithDrawalExport,
      })
    );
  }, []);

  const markComplete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to approve this request!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, approve it!'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          saveData({
            action: ApproveWithDrawalRequest,
            data: {
              id: id
            },
          })
        );
        Swal.fire(
          'Approved!',
          'The request has been approved.',
          'success'
        );
      }
    });
  };
  const viewUser = (id) => {
    NavigateTo("/withdrawal-view/"+id);
  };

const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";
const fileName = "withdrawal";

const exportToCSV = () => {
    const selectedFields = walletWithDrawalExport.walletWithDrawalExport.rows?.map(({ user, createdAt, sourceType, amount, bank, creatorAddress }) => ({ 
      name: user.name,  
      date: viewDateDDMMYYY(createdAt), 
      sourceType, 
      amount,
      acountNumber: bank?.acountNumber,
      holderName: bank?.holderName,
      routingNumber: bank?.bankIFSC,
      bankName: bank?.bankName,
      bankAddress: bank?.bankAddress,
      holderAddress: bank?.holderAddress,
      lane1: creatorAddress?.lane1,
      lane2: creatorAddress?.lane2,
      city: creatorAddress?.city,
      state: creatorAddress?.state,
      country: creatorAddress?.country,
      zipCode: creatorAddress?.zipCode,

    }));
    const ws = XLSX.utils.json_to_sheet(selectedFields);
    XLSX.utils.sheet_add_aoa(ws, [[
      "Name", 
      "Date", 
      "Request Mode", 
      "Amount", 
      "Acount Number",
      "Account Holder Name",
      "Routing Number",
      "Bank Name",
      "Bank Address",
      "Account Holder Address",
      "Address 1",
      "Address 2",
      "City",
      "State",
      "Country",
      "Zipcode"
    ]], { origin: "A1" });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
};

  return (
    <>
      <Header />
      <main>
        <SideBard />
        <div className="main">
          <div className="container">
            <div className="row">
              <div className="content-wrapper w-100 mb-5">
                <div className="d-flex aic jsb flw">
                  <h1 className="pageHeading my-4">
                    Withdrawal Request
                  </h1>
                  <div className="searchbar my-4">
                      <div className="input-group d-flex">
                      { walletWithDrawalExport && walletWithDrawalExport.walletWithDrawalExport.rows?.length ? (
                          <button className="btn btn-primary" onClick={(e) => exportToCSV()}> Export </button>
                        ) : (
                          <button className="btn btn-primary disable-export"> Export </button>
                        ) }
                      </div>
                  </div>
                </div>
                <div className="content">
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="table-responsive p-0">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th className="tal pl-4">Name</th>
                                  <th className="tac">Date and Time</th>
                                  <th className="tac">Request Mode</th>
                                  <th className="tac">Amount</th>
                                  <th className="tar pr-4">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {walletWithDrawal && walletWithDrawal.walletWithDrawal &&
                                  walletWithDrawal.walletWithDrawal.length > 0 &&
                                  walletWithDrawal.walletWithDrawal.map((v, k) => {
                                    return (
                                      <tr key={generateRandomString()}>
                                        <td className="pl-4">
                                          <div className="d-flex aic">
                                            <div className="icon icon-circle">
                                              <div className="icon-user">
                                                <img
                                                  src={v?.user?.profilePic}
                                                  alt=""
                                                />
                                              </div>
                                              <div className="icon_withdrawal">
                                                <i className="far fa-arrow-alt-circle-up"></i>
                                              </div>
                                            </div>
                                            <h6 className="text-bold">
                                              <span className="username">
                                                {v?.user?.name}
                                              </span>
                                            </h6>
                                          </div>
                                        </td>
                                        <td className="tac">
                                          <span className="wdate d-block">
                                            {viewDateDDMMYYY(v.createdAt)}
                                          </span>{" "}
                                          <span className="wtime d-block">
                                            {getHourMinute(v.createdAt)} {getAmPm(v.createdAt)}
                                          </span>
                                        </td>
                                        <td className="tac">
                                          <h6>
                                            <span className="reqMode">
                                              {v.sourceType}
                                            </span>
                                          </h6>
                                        </td>
                                        <td className="tac">
                                          <span className="amount">
                                            ${v.amount}
                                          </span>
                                        </td>
                                        <td className="tar pr-4 actbtn">
                                        <span
                                            className="iconbtn with-view"
                                            onClick={() => viewUser(v.id)}
                                          >
                                            <i className="far fa-eye"></i>
                                          </span>
                                          <span className="btn btn-line" onClick={e => markComplete(v.id)}>
                                            Approve
                                          </span>
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <Pagination
                          className="pagination-bar"
                          currentPage={currentPage}
                          totalCount={walletWithDrawal.totalElements}
                          pageSize={PageSize}
                          onPageChange={(page) => setCurrentPage(page)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default WalletWithdrawal;
