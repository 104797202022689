import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import SideBard from "./SideBard";
import LastLogin from "../../components/LastLogin";
import { useDispatch } from "react-redux";
import { fetchData, saveData } from "../../utils/api/apiHelper";
import useCustomNavigate from "../../utils/hooks/useCustomNavigate";
import {
  LoadLastLogin,
  UserApproveApi,
  LoadConsumerExpense,
} from "../../utils/api/actionConstants";
import { useCreator } from "../../hooks/storeHooks";
import Images from "../../themes/Images";

const ViewConsumer = () => {
  const [NavigateTo] = useCustomNavigate();

  const dispatch = useDispatch();
  const Creator = useCreator();

  useEffect(() => {
    if (!Creator || !Creator.creator || !Creator.creator.id) {
      NavigateTo("/");
    }
  }, []);

  useEffect(() => {
    dispatch(
      fetchData({
        action: LoadConsumerExpense,
        data: {
          userId: Creator.creator?.id,
          action: "lifeTimeSpent",
        },
      })
    );

    dispatch(
      fetchData({
        action: LoadLastLogin,
        data: {
          id: Creator.creator.id,
        },
      })
    );
  }, [dispatch]);

  const onApproveRejectSuspend = (status) => {
    dispatch(
      saveData({
        action: UserApproveApi,
        data: {
          id: Creator.creator?.id,
          status,
          type: "consumer",
        },
      })
    );
  };

  return (
    <>
      <Header />
      <main>
        <SideBard />
        <div className="main">
          <div className="container">
            <div className="row">
              <div className="content-wrapper w-100 mb-5">
                <div className="d-flex aic jsb flw">
                  <h1 className="pageHeading my-4">User Account Overview</h1>
                  <div className="button_group d-flex jsb aic flw">
                      <button
                        type="button"
                        className="btn btn-primary mb-2"
                        onClick={()=> NavigateTo("/user/chat/" + Creator.creator.id,false, {user: Creator.creator })}
                      >
                        <i className="far fa-comment-dots"></i>Message
                        <span className="btnLoader"></span>
                      </button>  
                  </div>
                </div>
                <div className="content">
                  <div className="row">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-6">
                          <div className="card">
                            <div className="card-body">
                              <div className="d-flex userDetails">
                                <div className="icon icon-circle icon-lg">
                                  <div className="icon-user">
                                    <img
                                      src={Creator?.creator?.profilePic || Images.useIcon}
                                      alt=""
                                    />
                                  </div>
                                </div>
                                <div className="userinfo">
                                  <div className="mb-3">
                                    <span className="text-bold lbl">Name</span>:
                                    <span className="username val">
                                      {Creator?.creator?.name}
                                    </span>
                                  </div>
                                  <div className="mb-3">
                                    <span className="text-bold lbl">Email</span>
                                    :
                                    <span className="username val">
                                      <span className="mlink">
                                        {Creator?.creator?.email}
                                      </span>
                                    </span>
                                  </div>
                                  <div className="mb-3">
                                    <span className="text-bold lbl">
                                      Phone No. <strong>:</strong>
                                    </span>

                                    <span className="username val">
                                      {Creator.creator?.mobileNumber}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="card h-100">
                            <LastLogin />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div class="card mb-4">
                        <div class="card-body">
                          <div class="d-flex jsb aic moneydetails">
                            <div class="Money">
                              <div class="totalMoneyearn">
                                ${Creator?.moneySpent || 0}
                              </div>
                            </div>
                            <div class="ipadrs">
                              <div class="lblmoney">Money Spent</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="button_group d-flex jsb aic flw">
                        {Creator.creator.isApproved === 0 && (
                          <button
                            type="button"
                            className="btn btn-green me-2 mb-2"
                            onClick={() => onApproveRejectSuspend("Approved")}
                          >
                            <i className="far fa-check-circle"></i> Approve
                            <span className="btnLoader"></span>
                          </button>
                        )}
                        {Creator.creator.isApproved === 0 && (
                          <button
                            type="button"
                            className="btn btn-red me-2 mb-2"
                            onClick={() => onApproveRejectSuspend("Rejected")}
                          >
                            <i className="far fa-times-circle"></i> Reject
                            <span className="btnLoader"></span>
                          </button>
                        )}
                        {Creator.creator.isApproved === 1 && (
                          <button
                            type="button"
                            className="btn btn-primary mb-2"
                            onClick={() => onApproveRejectSuspend("Suspend")}
                          >
                            <i className="fa fa-user-alt-slash"></i> Suspend
                            <span className="btnLoader"></span>
                          </button>
                        )}
                        {Creator.creator.isApproved > 1 && (
                          <button
                            type="button"
                            className="btn btn-primary mb-2"
                            onClick={() => onApproveRejectSuspend("Approved")}
                          >
                            <i className="fa fa-user-alt-slash"></i> Re Activate
                            <span className="btnLoader"></span>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default ViewConsumer;
