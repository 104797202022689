import _ from "lodash";
import { GET, POST, PUT, DELETE, UPLOAD } from "./Api";
import * as AllApis from "./apiRoutes";
import { toast } from "react-toastify";
import * as Validate from "../validatorXerox";
import { replaceValues } from "../helper";

const showError = (message) => {
  toast.error(message);
  toast.clearWaitingQueue();
};

export const fetchData = (data) => async (dispatch) => {
  let action = data.action;
  let url = AllApis[action];
  if (!url) {
    return "with error";
  }

  url = prepareUrl(url, data.data);

  return GET(url).then((r) => {
    dispatch({ type: action + "Loading", payload: false });
    if (r.status && r.status === 1) {
      dispatch({ type: action, payload: r.data });
      if (data.sendAll) {
        return r.data;
      }
      return 1;
    } else {
      // error etc.
      return 0;
    }
  });
};

export const fetchReportData = (data) => async (dispatch) => {
  let action = data.action;
  let url = AllApis[action];
  if (!url) {
    return "with error";
  }

  url = prepareUrl(url, data.data);
  if (data.data.lastMessageAt) {
    url = url + "?lastMessageAt=" + data.data.lastMessageAt;
  }
  return GET(url).then((r) => {
    dispatch({ type: action + "Loading", payload: false });
    if (r.status && r.status === 1) {
      dispatch({ type: action, payload: {
        query: data.data,
        data: r.data
      }});
      if (data.sendAll) {
        return r.data;
      }
      return 1;
    } else {
      // error etc.
      return 0;
    }
  });
};

export const saveData = (data) => async (dispatch) => {
  let action = data.action;
  let url = AllApis[action];
  if (!url) {
    return "with error";
  }

  url = prepareUrl(url, data.data);

  if (data.definition) {
    let validate = Validate.dynamicValidationNew(data.definition, data.data);
    if (validate !== "") {
      showError(validate);
      return Object.assign({}, data.data, validate);
    }
  }

  return POST(url, data.data).then((r) => {
    if (r.status && r.status === 1) {
      dispatch({ type: action, payload: r.data });
      dispatch({ type: action + "Update", payload: data.data });
      return 1;
    } else {
      showError(r && r.message);
      return 0;
    }
  });
};

export const updateData = (data) => async (dispatch) => {
  let action = data.action;
  let url = AllApis[action];
  if (!url) {
    return "with error";
  }

  url = prepareUrl(url, data.data);

  if (data.definition) {
    let validate = Validate.dynamicValidationNew(data.definition, data.data);
    if (validate !== "") {
      console.log(validate);

      return Object.assign({}, data.data, validate);
    }
  }

  return PUT(url, data.data).then((r) => {
    alert(r.message);
    if (r.code && r.code === 200) {
      dispatch({ type: action, payload: r.data });
      return 1;
    } else {
      return 0;
    }
  });
};

export const deleteData = (data) => async (dispatch) => {
  let action = data.action;
  let url = AllApis[action];
  if (!url) {
    return "with error";
  }

  url = prepareUrl(url, data.data);

  if (data.definition) {
    let validate = Validate.dynamicValidationNew(data.definition, data.data);
    if (validate !== "") {
      return Object.assign({}, data.data, validate);
    }
  }

  if (!data.data.id) {
    return 0;
  }

  url = url + "/" + data?.data?.id;

  return DELETE(url).then((r) => {
    alert(r.message);
    if (r.code && r.code === 200) {
      dispatch({ type: action, payload: data.data });
      return 1;
    } else {
      return 0;
    }
  });
};

export const saveFormData = (data) => async (dispatch) => {
  let action = data.action;
  let url = AllApis[action];
  if (!url) {
    return "with error";
  }

  // data * url parser.
  // Mustache can be used for that.

  return UPLOAD(url, data.data).then((r) => {
    console.log(r);

    if (r.status && r.status === 1) {
      return r.data;
    } else {
      showError(r && r.message);
      return r;
    }
  });
};

const prepareUrl = (url, data) => {
  if (url.indexOf("{{") !== -1) {
    url = replaceValues(url, data);
  }
  return url;
};
