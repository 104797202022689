
// Login Constants
export const LoginUser = "admin/login";

// ForgetPassword Constants
// export const ResetPassword = "user/resetpassword";
// export const UpdatePassword = "user/updatePassword";

// user based action
export const LoadUserToApprove =
  "admin/list-creator?offset={{offset}}&limit={{limit}}";
export const LoadUserForManagement =
  "admin/list-user?isApproved={{isApproved}}&offset={{offset}}&limit={{limit}}";
export const DeleteUser = "admin/delete-user"
export const SetUserAsFree = "admin/add-free-creator/{{id}}";
export const UserApproveApi = "admin/approve-user/{{id}}";
export const UserScoreApi = "admin/profile-score-user/{{id}}";
export const LoadSetFreeProfile = "admin/free-creator/{{id}}";

export const LoadLastLogin = "user/last-login/{{id}}";
export const LoadMoney = "LoadMoney";

export const ApproveUser = "admin/approve-user/{{id}}";
export const DisapproveUser = "admin/disapproved-user";
export const ListUsersContentSharedwith = "admin/creator/sharedwith/{{id}}";
export const ListContentSharedwith =
  "admin/creator/{{creator}}/sharedwith/{{consumer}}?offset={{offset}}&limit={{limit}}";

export const ListCreatorContentX =
  "admin/list-creatorcontent/X/{{id}}?lastMessageAt={{lastMessageAt}}";
export const ListCreatorContentR =
  "admin/list-creatorcontent/R/{{id}}?lastMessageAt={{lastMessageAt}}";

//export const ListCreatorPrivatePics ="admin/list-creatorcontent/Private/{{id}}?limit=10";

export const ListCreatorPrivatePics ="admin/list-creatorcontentpfIntroPriFol/{{id}}?limit=10";
  
export const ListCreatorProfilePics = "admin/list-profilepic/{{id}}";

export const LoadSortCreator = "admin/load-creator/";
export const SortCreator = "admin/sort-creator/";

export const LoadWithDrawalRequest =
  "admin/withdrawal?offset={{offset}}&limit={{limit}}";

export const LoadWithDrawalExport ="admin/withdrawalExport";

export const ApproveWithDrawalRequest = "admin/withdrawal/{{id}}";

export const LoadWithDrawalRequestById = "admin/withdrawalView/{{id}}";

export const LoadWithPaymentHistory =
  "admin/payment-history?offset={{offset}}&limit={{limit}}";
export const LoadWithSubscriptions =
"admin/subscription?offset={{offset}}&limit={{limit}}";

export const LoadReports = "admin/report-user?offset={{offset}}&limit={{limit}}";
export const ResolveReports = "admin/report-user/{{id}}";
export const getStaticPage = "admin/staticPages/{{page}}";
export const saveStaticPage = "admin/staticPages";

export const LoadSubscriberForManagement = "admin/list-subscriber?offset={{offset}}&limit={{limit}}";

// dashboard
export const LoadDashboardCode = "reports/admin?action={{action}}";
export const LoadCreatorEarnings = "reports/admin?action={{action}}&userId={{userId}}";
export const LoadConsumerExpense = "reports/admin?action={{action}}&userId={{userId}}";

// chat
export const LoadChats = "admin/get-chats/{{id}}";
export const LoadChatMessage = "admin/message/{{id}}";